<template lang="pug">
.page-wrapper.w-100.py-5.px-2.w-100
  .w-100.page-wrapper--container.align-items-start
    .d-flex.flex-column.justify-content-start.align-items-start
      om-heading.mb-5.mt-5(v-if="title" h1) {{ title }}
      slot(name="content")
    .flex-column.justify-content-start.align-items-end.sm-hidden.mt-5
      slot(name="image")
</template>

<script>
  export default {
    name: 'PageWrapper',
    props: {
      title: { type: String, default: null },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/helpers/media-breakpoint"
  .sm-hidden
    display: flex
    @include media-breakpoint-down(sm)
      display: none
  @container (min-width: 1200px)
    .page-wrapper--container
      max-width: 1200px
      margin: auto
  .page-wrapper
    container: card / inline-size
    &--container
      display: grid
      grid-template-columns: auto min(350px, 30%)
      @include media-breakpoint-down(lg)
        grid-template-columns: auto min(250px, 25%)
      @include media-breakpoint-down(md)
        grid-template-columns: auto min(150px, 20%)
      @include media-breakpoint-down(sm)
        grid-template-columns: 100%
</style>
